@import 'tale/variables';
@import 'tale/base';
@import 'tale/code';
@import 'tale/post';
@import 'tale/syntax';
@import 'tale/layout';
@import 'tale/pagination';
@import 'tale/catalogue';
@import 'tale/tags';

main, .nav-container {
    max-width: 900px;
}
