.tags {
  &-header {
    &-title {
      color: $default-shade;
      font-family: $sans-serif;
      font-size: 2rem;
      margin: 1rem 0;
      text-align: center;
    }


    &-line {
      border-top: 0.4rem solid $default-shade;
      display: block;
      margin: 0 auto 3rem;
      width: 4rem;
    }
  }


  &-clouds {
    text-align: center;
    font-family: $sans-serif;


    a {
      display: inline-block;
      margin: 0 0.1rem 0.2rem;
      padding: 0.2rem 0.5rem;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      color: $default-color;
      text-decoration: none;


      &:hover,
      &:active {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }


  &-item {
    &-icon {
      height: 1rem;
    }


    &-label {
      display: inline-block;
      margin: 2rem 0 0.5rem;
      font-family: $sans-serif;
      color: $default-color;
    }
  }


  &-post {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;


    &-title {
      color: $default-color;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    &-line {
      @include transition(all 0.3s ease-out);
      border-top: 0.1rem solid $default-shade;
      display: block;
      width: 0;
    }


    &-meta {
      color: $default-tint;
      text-align: right;
      white-space: nowrap;
    }


    &:hover,
    &:active {
      .tags-post-line {
        width: 3rem;
      }


      .tags-post-meta {
        color: $default-shade;
      }
    }
  }
}
